import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, Subject } from 'rxjs';
import { IUser } from '@core/interfaces/users/user.interface';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _users$ = new Subject<IUser[]>();
  private _roles$ = new Subject<any>();
  private _foundations$ = new Subject<any>();

  constructor(private _http: HttpClient) {}

  get apiUrl(): string {
    // return 'http://localhost:5800/dataservice/offertool';
    return `${localStorage.getItem('api-url')}/offertool`;
  }

  getUsers(page) {
    const allParamValues: HttpParams = new HttpParams()
      .set('page', `${page?.pageIndex || 0}`)
      .set('size', `${page?.pageSize || 10}`);
    const options = {
      params: allParamValues,
    };
    return this._http.get<any>(`${this.apiUrl}/users`, options).pipe(
      tap((users) => {
        this._users$.next(users);
      })
    );
  }
  createUser(tenant: string, personalData: any): Observable<any> {
    return this._http.post<any>(`${environment.authApiUrl}/auth/createOfferToolUser?tenant=${tenant}`, personalData);
  }
  updateUser(id, tenant, personalData) {
    return this._http.put<any>(
      `${environment.authApiUrl}/auth/updateOfferToolUser/${id}?tenant=${tenant}`,
      personalData
    );
  }
  updateUserMyAccount(id, tenant, personalData) {
    return this._http.put<any>(`${environment.authApiUrl}/auth/updateMyAccount/${id}?tenant=${tenant}`, personalData);
  }
  deleteUser(id, tenant) {
    return this._http.delete<any>(`${environment.authApiUrl}/auth/deleteOfferToolUser/${id}?tenant=${tenant}`);
  }

  getCurrentUserById(id: string) {
    return this._http.get<IUser>(`${this.apiUrl}/users/current-user?id=${id}`);
  }

  getCurrentUser(id) {
    return this._http.get<IUser>(`${this.apiUrl}/users/current-user/${id}`);
  }
  getUserRoles() {
    return this._http.get<any[]>(`${this.apiUrl}/users/roles`).pipe(
      tap((roles) => {
        roles.sort((a, b) => a.name - b.name);
        this._roles$.next(roles);
      })
    );
  }
  getUserFoundations() {
    return this._http.get<any>(`${this.apiUrl}/users/foundation`).pipe(
      tap((foundations) => {
        foundations.sort((a, b) => a.name - b.name);
        this._foundations$.next(foundations);
      })
    );
  }
}
